body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*background: #FFF9ED;*/
  background: #fdfaf3;
  /*background: #f8f8f8;*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.moving-gradient {
  height: 100%;
  width: 100%;
  background: linear-gradient(45deg, transparent 25%, rgba(255, 255, 255, 0.5) 50%, transparent 75%);
  background-color: grey;
  animation: gradient 3s linear infinite;
  background-size: 200% 100%;
}

@keyframes gradient {
  0% {
    background-position: 200% 0;
  }

  100% {
    background-position: -200% 0;
  }
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(15px);
  }

  to {
    transform: translateY(0px);
  }
}